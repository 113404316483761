<template>
	<div class="healthSupervision">
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline search-form">
				<el-form-item label="机构名称：">
					<el-autocomplete v-model.trim="formInline.name" :fetch-suggestions="querySearchAsync" clearable placeholder="请输入内容"></el-autocomplete>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSearch" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="main-content">
			<div class="tabClass">
				<span :class="{ activeClass: checkTab === 1 }" @click="changeTab(1)">信息报告登记表</span>
				<span :class="{ activeClass: checkTab === 2 }" @click="changeTab(2)">巡查登记表</span>
			</div>
			<div class="main-content-title">
				<p>共有 {{ total }} 条结果</p>
			</div>
			<Table
				:loading="loading"
				:table-data="tableData"
				:total="total"
				:table-head="checkTab == 1 ? tableHead : tableHeadCheck2"
				:current-page.sync="queryData.current"
				:page-size.sync="queryData.size"
				:is-show-selection="false"
				:operation-column-width="140"
				:showOperation="true"
				@change-page="getList"
				:tableHeight="tableHeight"
			>
				<template #operation="{ scope }">
					<el-button @click="modifyParkInfo(scope.row)" size="mini" type="primary"> 查看 </el-button>
				</template>
			</Table>
		</div>
	</div>
</template>

<script>
export default {
	name: 'healthyText',
	components: {
		Table: () => import('@/components/Table/table'),
	},
	data() {
		return {
			loading: false,
			formInline: {
				name: '',
			},
			checkTab: 1,
			queryData: {
				current: 1,
				size: 10,
			},
			total: 0,
			tableData: [],
			tableHead: [
				{
					label: '机构名称',
					prop: 'medicalOrganization',
					formatter: (row) => {
						return row.medicalOrganization?.title || '-';
					},
				},
				{
					label: '信息类别',
					prop: 'informationTypeDictionaryItem',
					formatter: (row) => {
						return row.informationTypeDictionaryItem?.title || '-';
					},
				},
				{
					label: '发现时间',
					prop: 'discoverTime',
					formatter: (row) => {
						return row.discoverTime || '-';
					},
				},
				{
					label: '报告人',
					prop: 'reporterName',
					formatter: (row) => {
						return row.reporterName || '-';
					},
				},
				{
					label: '联系电话',
					prop: 'phoneNumber',
					formatter: (row) => {
						return row.phoneNumber || '-';
					},
				},
				{
					label: '报告时间',
					prop: 'createTime',
					formatter: (row) => {
						return row.createTime || '-';
					},
				},
			],
			tableHeadCheck2: [
				{
					label: '机构名称',
					prop: 'medicalOrganization',
					formatter: (row) => {
						return row.medicalOrganization?.title || '-';
					},
				},
				{
					label: '查巡地点',
					prop: 'spot',
					formatter: (row) => {
						return row.spot || '-';
					},
				},
				{
					label: '查巡内容',
					prop: 'inspectionTypeDictionaryItem',
					formatter: (row) => {
						return row.inspectionTypeDictionaryItem?.title || '-';
					},
				},
				{
					label: '查巡人',
					prop: 'inspectorName',
					formatter: (row) => {
						return row.inspectorName || '-';
					},
				},
				{
					label: '联系电话',
					prop: 'phoneNumber',
					formatter: (row) => {
						return row.phoneNumber || '-';
					},
				},
				{
					label: '报告时间',
					prop: 'createTime',
					formatter: (row) => {
						return row.createTime || '-';
					},
				},
			],
			isShowDetail: false,
			options: [],
			filterLoading: false,
		};
	},
	computed: {
		tableHeight() {
			return window.innerHeight - 300;
		},
	},
	mounted() {
		this.getList();
	},
	methods: {
		// 搜索建议
		querySearchAsync(queryString, cb) {
			queryString ? this.getList('filter') : '';
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				cb(this.options);
			}, 1000 * Math.random());
		},
		onSearch() {
			this.queryData.current = 1;
			this.getList();
		},
		//获取列表
		getList(type = '') {
			type == 'filter' ? (this.filterLoading = true) : (this.loading = true);
			let data = {
				organizationName: this.formInline.name,
				...this.queryData,
			};
			let url = this.checkTab === 1 ? '/management-api/v1/PatrolSupervisions.json' : '/management-api/v1/PatrolInspections.json';
			this.$http
				.get(url, { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						if (type == 'filter') {
							this.filterLoading = false;
							this.options = res.data.collection.map((item) => {
								return {
									...item,
									value: item.medicalOrganization.title,
								};
							});
						} else {
							this.tableData = res.data.collection || [];
							this.total = res.data.pagination.totalItems || 0;
							this.loading = false;
							this.$refs.createView.link = res.data._links.create.href || '';
						}
					}
				})
				.catch((e) => {
					this.loading = false;
				});
		},
		// 查看详情
		modifyParkInfo(row) {
			this.$router.push({
				path: '/publicHealth/healthSupervision/details',
				query: {
					id: row.id,
					type: this.checkTab,
					name: row.medicalOrganization?.title || '-',
					year: row.createTime.slice(0, 4),
				},
			});
		},
		changeTab(val) {
			this.checkTab = val;
			this.formInline.name = '';
			this.options = [];
			this.getList();
		},
	},
};
</script>

<style lang="scss" scoped>
/deep/ .search-form {
	.el-select {
		width: 120px;
		.el-input {
			width: 120px;
			.el-input__inner {
				width: 120px;
			}
		}
	}
	.el-input {
		width: 160px;
	}
}
.main-content {
	.tabClass {
		width: calc(100% + 32px);
		line-height: 48px;
		border-bottom: 1px solid rgba($color: #000, $alpha: 0.1);
		margin-left: -16px;
		padding: 0 16px;
		span {
			display: inline-block;
			line-height: 48px;
			margin-right: 32px;
			font-size: 16px;
			cursor: pointer;
			&:hover {
				color: #1db9b1;
				border-bottom: 2px solid #1db9b1;
			}
		}
		.activeClass {
			color: #1db9b1;
			border-bottom: 2px solid #1db9b1;
		}
	}
	&-title {
		line-height: 48px;
		display: flex;
		justify-content: space-between;
		p {
			color: #666;
		}
		.el-button {
			line-height: 32px;
			padding: 0 16px;
			height: 32px;
			margin-top: 8px;
		}
	}
}
</style>